.portfolio {
	padding-top: 100px;
	/* padding-bottom: 50px; */
	text-align: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
	overflow: hidden;
}

.body-container {
    margin: 0;
    padding: 0;
}


.button-container2 {
    display: flex;
    gap: 30px;
  }
  
  .toggle-button2 {
    background: none;
    border: none;
    font-size: 18px; /* Reduced button size */
	font-weight: 600;
    position: relative;
    padding-bottom: 2px; /* Reduced padding */
    cursor: pointer;
    color: #333; /* Default text color */
  }
  
  /* .toggle-button:hover,
  .toggle-button.active {
    color: #BE3030; Text color change on hover or active
  } */
  
  .toggle-button2.active::after,
  .toggle-button2:hover::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #BE3030;
    width: 100%; /* Full underline width on hover */
    transition: width 0.3s ease-in-out; /* Smooth transition for the underline */
  }
  
  .toggle-button2::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #BE3030; /* Color for the underline */
    width: 0%; /* Initially set width to 0% */
    transition: width 0.3s ease-in-out; /* Animate width change */
  }

/* Image grid styling */
.image-grid {
    margin-top: 30px;
    padding: .2rem;
    display: grid;
    border-radius: 10px;
    gap: 0.2rem;
    grid-template-columns: repeat(6, 1fr);
}

.image-grid .image-div {
    width: 100%;
    height: 500px;
    overflow: hidden; /* Prevents the image from spilling outside when scaled */
    border-radius: 5px; /* Keep rounded corners */
}

.image-grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.5s ease; /* Smooth transition for scaling */
}

/* Styling for the overlay */
.portfolio .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    transition: opacity .7s ease;
    border-radius: 5px;
    z-index: 1;
    height: 100%;
}

.image-grid-item {
    position: relative;
    border-radius: 5px;
}

/* Show overlay on hover */
.image-grid-item:hover .overlay1 {
    cursor: pointer;
    opacity: .6;
    background-color: black;
}

/* Scale the image on hover */
.image-grid-item:hover img {
    transform: scale(1.15); /* Zoom in the image */
}

/* Additional styling for the text inside the overlay */
.portfolio .overlay1 .row1 {
	position: relative;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Space out elements within the overlay */
    align-items: center; /* Center elements horizontally */
    width: 100%; /* Ensure the row takes up full width */
    height: 100%; /* Ensure the row takes up full height */
    padding: 30px; /* Add some padding */
    text-align: center; /* Center text */
}

.portfolio .overlay1 .name {
	/* position: absolute; */
	/* top: 20px; */
	font-size: 1rem;
	margin-top: 0px;
	font-weight: 600;
}

.portfolio .overlay1 .play-button {
	width: 50px;
	height: 50px;
	/* margin-top: 10px !important;  */

}

.portfolio .overlay1 .company-name {
    font-size: 1.2rem;
    font-weight: 600;
	/* margin-top: 100px; */
}

.portfolio .close-button1 {
	position: absolute;
	top: .2rem;
	right: .2rem;
	/* background-color: #BE3030; */
	color: white;
	/* padding: 14px 25px; */
	font-size: 16px;
	font-weight: 500;
	border-radius: 30px;
	border: 0;
	outline: 0;
	/* padding: 0.5rem 1rem; */
	border: none;
	/* border-radius: 0.5rem; */
	cursor: pointer;
	width: 50px;
  	z-index: 1003;
  }
 
  .portfolio .close-button1:hover {
	/* background: white; */
	/* color: #BE3030; */
	transform: scale(1.2);
	transition: 0.3s;
	
  } 

  .portfolio  .video-player {
	position: fixed;
	top: 80px;
	left: 50px;
	right: 50px;
	bottom: 50px;
	/* background-color: rgba(0, 0, 0, 0.8); */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1002;
  }

  /* .button-container .button-margin{
	margin-top: 50px;
	margin-bottom: 50px;
  } */

  .portfolio  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
}

.portfolio .btn.button-touch2 {
  position: relative;
  padding: 10px 30px;
  background-color: #BE3030;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center; /* Aligns text and line vertically */
  height: 45px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  overflow: hidden;
  z-index: 2;
  border: solid 2px #BE3030;
  transition: 1s;
}

.portfolio .btn.button-touch2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0%;
  width: 100%;
  background-color: white;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: height 1s ease;
  border-radius: 5px;
}

.portfolio .btn.button-touch2:hover::before {
  height: 100%;
  bottom: 0;
} 

.portfolio .btn.button-touch2 .line {
  margin-left: 10px;
  position: relative;
  width: 70px; /* Fixed width for the line */
  height: 2px;
  background-color: white;
}

.portfolio .btn.button-touch2 .circle {
  position: absolute;
  top: -3px; /* Position the circle to align with the middle of the text */
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  animation: moveCircle 4s linear infinite;
}

.portfolio .btn.button-touch2:hover {
  /* background: white; */
  color: #BE3030;
  border: solid 2px #BE3030;
}

.portfolio .btn.button-touch2:hover .line {
  background: #BE3030;
  color: #BE3030;
}

.portfolio .btn.button-touch2:hover .circle {
  background: #BE3030;
  color: #BE3030;
}

@keyframes moveCircle {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@media (max-width: 1600px) {
	.image-grid .image-div  {
		height: 400px;
	}
  }

  @media (max-width: 1250px) {
	.image-grid {
		grid-template-columns: repeat(4, 1fr);
	}
  }

  @media (max-width: 768px) {
	.image-grid {
		grid-template-columns: repeat(3, 1fr);
	}
	.portfolio.overlay1 .name {
		font-size: .9rem;
	}
	.portfolio .overlay1 .play-button {
		width: 40px;
		height: 40px;
	}
	
	.portfolio .overlay1 .company-name {
		font-size: 1rem;
		font-weight: 500;
	}
	/* .video-player {
		top: 300px;
		left: 20px;
		right: 20px;
		bottom: 300px;
	} */
	.portfolio .close-button1 {
		position: absolute;
		top: .5rem;
		right: .5rem;
	}
  }

  @media (max-width: 600px) {
    .portfolio {
      padding-top: 0px;
    }
    
	.image-grid .image-div  {
		height: 300px;
	}

	.portfolio .overlay1 .name {
		font-size: .6rem;
	}
	
	.portfolio .overlay1 .play-button {
		width: 30px;
		height: 30px;
	
	}
	
	.portfolio .overlay1 .company-name {
		font-size: .6rem;
		font-weight: 500;
	}
  }

  @media (max-width: 470px) {
	.image-grid .image-div {
		height: 200px;
	}

	.portfolio .btn.button-touch2 {
		padding: 10px 20px;
		font-size: 16px;
	  }

	  .portfolio .btn.button-touch2 .line {
		width: 50px; /* Fixed width for the line */
	  }
  }

  @media (max-width: 350px) {
	.portfolio .overlay1 .name {
		font-size: .5rem;
	}

  .portfolio .overlay1 .company-name {
		font-size: .5rem;
		font-weight: 500;
	}
  }