.hero {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh; 
  position: relative;
  overflow: hidden;
}

.hero video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

/* .hero iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
} */

/* .hero .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
} */

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 1;
}

.hero .hero-content {
    position: absolute;
    color: white;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width: 1000px; */
    z-index: 2;
}

.hero .hero-content h1 {
  font-size: 50px;
  max-width: 700px;
  text-align: left;
  margin-right: auto;
}

.hero .hero-content h1 .color {
  color: #BE3030;
}

.hero .hero-content .review-button {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: auto;
}

.hero .clutch-review {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 10px;
}

.hero .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.hero .left img {
  width: 80px;
}

.hero .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.hero .right .rating {
  display: inline-block;
  line-height: 1;
}

.hero .right .star {
  color: #BE3030;
  font-size: 1rem;
}

.hero .btn.button-touch2 {
  position: relative;
  padding: 10px 30px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: left; /* Aligns text and line vertically */
  height: 45px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-right: auto;
  margin-top: 30px;
  border: solid 2px white;
  transition: 1s;
}

.hero .btn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0%;
  width: 100%;
  background-color: #BE3030;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: height 1s ease;
  border-radius: 5px;
}

.hero .btn:hover::before {
  height: 100%;
  bottom: 0;
} 

.hero .btn.button-touch2 .line {
  margin-left: 10px;
  position: relative;
  width: 70px; /* Fixed width for the line */
  height: 2px;
  background-color: white;
  transition: .5s;
}

.hero .btn.button-touch2 .circle {
  position: absolute;
  top: -3px; /* Position the circle to align with the middle of the text */
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  animation: moveCircle 4s linear infinite;
  transition: .5s;
}

.hero .btn.button-touch2:hover {
  /* background: #BE3030; */
  /* color: #BE3030; */
  /* border: solid 2px #BE3030; */
  transition: .5s;
}

.hero .btn.button-touch2:hover .line {
  /* background: #BE3030; */
  /* color: #BE3030; */
  transition: .5s;
}

.hero .btn.button-touch2:hover .circle {
  /* background: #BE3030; */
  /* color: #BE3030; */
  transition: .5s;
}

@keyframes moveCircle {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

.hero .scroll {
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  justify-content: center;
  justify-items: center;
  text-align: center;
  bottom: 10%;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}

.hero .scroll img {
  width: 30px;
}

.hero .scroll h1 {
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.hero .whatsapp-icon {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000;
}

.hero .whatsapp-icon:hover {
  transform: scale(1.05);
}

.hero .whatsapp-icon {
  display: inline-block;
  /* border-radius: 50%;  */
  animation: blink 2s infinite; 
}

/* .hero .whatsapp-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid rgba(255, 0, 0, 0.5); 
  border-radius: 50%; 
  animation: blink-border 1.5s infinite;
} */

@keyframes blink {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.02);
      opacity: 0.5;
  }
}

/* @keyframes blink-border {
  0%, 100% {
      border-color: rgba(255, 0, 0, 0.5);
  }
  50% {
      border-color: rgba(255, 255, 0, 1);
  }
} */

/* Adjustments for smaller screens */
@media (max-width: 1000px) {
  .hero .hero-content {
    margin-left: 10px;
  }
  .hero .hero-content h1 {
    font-size: 35px;
    text-align: center;
    max-width: 100%;
  }
  .hero .hero-content .review-button {
    margin-right: auto;
    margin-left: auto;
  }
}


@media (max-width: 768px) {
  .hero .hero-content {
    text-align: center;
    padding-left: 20px;
    padding-left: 20px;
  }

  .hero .hero-content h1 {
    font-size: 30px;
    text-align: center;
  }

  .hero .whatsapp-icon {
      bottom: 10px;
      left: 10px;
  }

  .hero .scroll {
    bottom: 20%;
  }
  
  .hero .scroll img {
      width: 17px;
  }
  
  .hero .scroll h1 {
    max-width: fit-content;
      font-size: 12px;
  }

  .hero .btn.button-touch2 {
    text-align: center;
    margin-left: auto;
    padding: 10px 15px;
    height: 45px;
    font-size: 16px;
  }
  
  .hero .btn.button-touch2 .line {
    margin-left: 10px;
    position: relative;
    width: 50px; /* Fixed width for the line */
    height: 2px;
  }
}

/* Hide WhatsApp icon on very small screens */
@media (max-width: 500px) {
  .hero .hero-content {
    margin-left: 25px;
  }
  .hero .hero-content .review-button {
    display: block;
    align-items: center;
    gap: 20px;
    margin-right: auto;
  }
}

@media (max-width: 380px) {
  .hero .hero-content h1 {
    font-size: 22px;
  }
}

/* Adjustments Video for shorter viewports */
@media (max-height: 800px) {
  .hero video {
      height: 800px;
  }
}

@media (max-height: 700px) {
  .hero video {
      height: 700px;
  }
}

@media (max-height: 600px) {
  .hero video {
      height: 600px;
  }
}